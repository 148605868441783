<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <h3 class="form-edit-header">{{ $t('video.main_content') }}</h3>
        <input type="hidden" v-model="video.id">
        <small
          v-if="video.meta.title.length < 50"
          class="form-control-feedback text-warning float-right"
        >
          <i class="fa fa-exclamation-triangle"></i>
          {{ $t('video.title_is_too_short') }}
        </small>
        <small
          v-if="video.meta.title.length > 95"
          class="form-control-feedback text-warning float-right"
        >
          <i class="fa fa-exclamation-triangle"></i>
          {{ $t('video.title_is_too_long') }}
        </small>
        <app-input
          v-model.trim="video.meta.title"
          @blur="validations.video.meta.title.$touch()"
          :error="validations.video.meta.title.$error"
          id="video_meta_title"
          :label="$t('video.meta_title')"
          :disabled="disabled"
          required
          show-counter
          separateCounter
        >
        </app-input>
      </div>
      <div class="col-lg-12">
        <small
          v-if="video.meta.description.length < 10"
          class="form-control-feedback text-warning float-right"
        >
          <i class="fa fa-exclamation-triangle"></i>
          {{ $t('video.description_is_too_short') }}
        </small>
        <small
          v-if="video.meta.description.length > 300"
          class="form-control-feedback text-warning float-right"
        >
          <i class="fa fa-exclamation-triangle"></i>
          {{ $t('video.description_is_too_long') }}
        </small>
        <app-textarea
          v-model.trim="video.meta.description"
          @blur="validations.video.meta.description.$touch()"
          :error="validations.video.meta.description.$error"
          id="video_meta_description"
          :label="$t('video.meta_description')"
          :disabled="disabled"
          required
          show-counter
        >
        </app-textarea>
      </div>
      <div class="col-lg-12">
        <app-input
          v-model.trim="video.meta.keywords"
          @blur="validations.video.meta.keywords.$touch()"
          :error="validations.video.meta.keywords.$error"
          id="video_meta_keywords"
          :label="$t('video.meta_keywords')"
          :disabled="disabled"
          required
          show-counter
        >
        </app-input>
      </div>
      <div class="col-lg-12">
        <label :class="{'error': validations.video.authorUsers.$error}">
          {{ $t('video.author') }}
          <span class="required">*</span>
        </label>
        <app-multi-select
          v-model="authorUsersIds"
          :options="users"
          :disabled="disabled"
          label="username"
          track-by="id"
          id="video_authorUsers"
          data-attr="authors_list"
        >
        </app-multi-select>
        <SourceSearchSelect
          v-if="dataLoaded"
          v-model="video.source"
          :disabled="disabled"
          id="video_source"
        />
      </div>
      <div class="col-lg-12">
        <label>{{ $t('video.camera') }}</label>
        <app-multi-select
          v-model="cameraUsersIds"
          :options="users"
          :disabled="disabled"
          label="username"
          track-by="id"
          id="video_cameraUsers"
          data-attr="cameraUsers_list"
        >
        </app-multi-select>
      </div>
      <div class="col-lg-12">
        <label>{{ $t('video.editing') }}</label>
        <app-multi-select
          v-model="editingUsersIds"
          :options="users"
          :disabled="disabled"
          label="username"
          track-by="id"
          id="video_editingUsers"
          data-attr="editingUsers_list"
        >
        </app-multi-select>
      </div>

      <div class="col-lg-12">
        <h3 class="form-edit-header">{{ $t('video.headline_used_in_articles') }}</h3>
        <p
          class="alert alert-info"
          v-if="video.articles.length === 0"
        >
          {{ $t('video.video_article_not_found') }}
        </p>
        <app-video-articles
          v-else
          :video="video"
        >
        </app-video-articles>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import MultiSelect from '@/components/form/select/MultiSelect'
import VideoArticles from '../../components/video/VideoArticles'
import SourceSearchSelect from '@/components/author/SourceSearchSelect'

export default {
  name: 'VideoMainContent',
  components: {
    SourceSearchSelect,
    appInput: Input,
    appTextarea: Textarea,
    appVideoArticles: VideoArticles,
    appMultiSelect: MultiSelect
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dataLoaded: {
      type: Boolean,
      default: false
    },
    video: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  computed: {
    users () {
      return this.$store.getters['user/all']
    },
    authorUsersIds: {
      set (newValue) {
        this.video.authorUsers = this._.map(newValue, 'id')
      },
      get () {
        return this.users.filter(object => this.video.authorUsers.includes(object.id))
      }
    },
    cameraUsersIds: {
      set (newValue) {
        this.video.cameraUsers = this._.map(newValue, 'id')
      },
      get () {
        return this.users.filter(object => this.video.cameraUsers.includes(object.id))
      }
    },
    editingUsersIds: {
      set (newValue) {
        this.video.editingUsers = this._.map(newValue, 'id')
      },
      get () {
        return this.users.filter(object => this.video.editingUsers.includes(object.id))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-edit-header {
  font: 400 rem(22px) "Roboto";
  color: #465674;
}
::v-deep .textarea__label {
  margin-bottom: 0;
}
.multiselect-area {
  margin-bottom: 1.125rem;
}
.article-info:hover {
  color: #fff;
}
</style>
